import { SpecialAction, SpecialActionContext } from '../special-action';

const RIGHT_SALES = 'AKTION_HANDEL';
const RIGHT_PARTNER = 'AKTION_PARTNER';
export const FROM_DATE = new Date(2025, 2, 10).getTime();
export const TILL_DATE = new Date(2025, 2, 22).getTime();

export class StorageAction extends SpecialAction {
    shouldShow(ctx: SpecialActionContext): boolean {
        return this.isQualified(ctx.userRoles) && this.isInDateRange();
    }

    getImagePath(ctx: SpecialActionContext): string {
        const percentage = ctx.userRoles.includes(RIGHT_SALES) ? 5 : 3;
        return `./assets/Einlagerungsaktion_Teaser_CustomerCenter_2025_${percentage}Prozent.png`;
    }

    private isQualified(roles: string[]): boolean {
        return [RIGHT_SALES, RIGHT_PARTNER].some(r => roles.includes(r));
    }

    private isInDateRange(): boolean {
        const now = new Date().getTime();
        return now >= FROM_DATE && now < TILL_DATE;
    }
}
