import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { RolesProviderService } from '../shared/auth/roles-provider-service';
import { SessionService } from '../shared/session.service';
import { CartDiscountAction } from './cart-discount/cart-discount-action';
import { SpecialAction, SpecialActionContext } from './special-action';
import { SpecialActionsDialogComponent } from './special-actions-dlg.component';
import { StorageAction } from './storage-action/storage-action-25';

// Caution: Order matters, first to match conditions wins!
const ACTIONS: SpecialAction[] = [new StorageAction(), new CartDiscountAction()];

@Injectable({
    providedIn: 'root',
})
export class SpecialActionsService {
    constructor(
        private sessionService: SessionService,
        private rolesProviderService: RolesProviderService,
        private matDialog: MatDialog
    ) {}

    showDialogIfNeeded() {
        const sess = this.sessionService.getSessionSnapshot();

        if (!sess) {
            return;
        }

        this.rolesProviderService
            .getRoles()
            .pipe(take(1))
            .subscribe(roles => {
                const ctx: SpecialActionContext = { userEmail: sess.name, userRoles: roles };
                for (const action of ACTIONS) {
                    if (action.shouldShow(ctx)) {
                        this.matDialog.open(SpecialActionsDialogComponent, { data: action.getImagePath(ctx) });
                        return;
                    }
                }
            });
    }
}
