import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { RolesProviderService } from '../shared/auth/roles-provider-service';
import { SessionService } from '../shared/session.service';
import { UserInfoEndpointService } from './default/services';

export const WK_RIGHT = 'WK_ANZ';
export const OPOS_RIGHT = 'OP_ANZ';
export const COLLECTION_ONLY = 'ABH_ANZ';
export const DELIVERY_ONLY = 'NUR_VERSAND';
export const USA_CUSTOMER = 'USA_ANZ';
export const COA_RIGHT = 'SHOW_GLB';
export const ORDERS_RIGHT = 'AUFTRAG_ANZ';
export const ITEMS_RIGHT = 'PRODUKT_ANZ';

@Injectable()
export class OxRolesProviderService extends RolesProviderService {
    private rolesSnapshot: string[] = [];

    private roles$ = this.sessionService.isAuthenticated$.pipe(
        switchMap(isAuthenticated => {
            if (!isAuthenticated) {
                this.rolesSnapshot = [];
                return of<string[]>([]);
            }

            return this.userInfoService.getUserAuthorizationsUsingGET().pipe(
                map(rolesDict => this.extractRoles(rolesDict)),
                tap(roles => (this.rolesSnapshot = roles)),
                catchError(err => {
                    console.error(err);
                    return of<string[]>([]);
                })
            );
        }),
        shareReplay(1)
    );

    constructor(private sessionService: SessionService, private userInfoService: UserInfoEndpointService) {
        super();
    }

    public getRoles() {
        return this.roles$;
    }

    public canViewCartAndOrder(): Observable<boolean> {
        return this.hasRole(WK_RIGHT);
    }

    public canViewOpenPositions(): Observable<boolean> {
        return this.hasRole(OPOS_RIGHT);
    }

    public canSelectDeliveryOnly(): Observable<boolean> {
        return this.hasRole(DELIVERY_ONLY);
    }

    public canSelectCollectionOnly(): Observable<boolean> {
        return this.hasRole(COLLECTION_ONLY);
    }

    public canViewCoa(): Observable<boolean> {
        return this.hasRole(COA_RIGHT);
    }

    public isUsaCustomer(): Observable<boolean> {
        return this.hasRole(USA_CUSTOMER);
    }

    public canViewOrders(): Observable<boolean> {
        return this.hasRole(ORDERS_RIGHT);
    }

    public canViewItems(): Observable<boolean> {
        return this.hasRole(ITEMS_RIGHT);
    }

    public hasRole(role: string): Observable<boolean> {
        return this.roles$.pipe(map(roles => roles.includes(role)));
    }

    public getRolesSnapshot(): string[] {
        return this.rolesSnapshot;
    }

    private extractRoles(rolesDict: { [key: string]: string[] }): string[] {
        const effectiveRoles: string[] = [];
        for (const user in rolesDict) {
            if (user in rolesDict) {
                const userRoles = rolesDict[user];
                effectiveRoles.push(...userRoles);
            }
        }

        return effectiveRoles;
    }
}
