import { Observable } from 'rxjs';

export abstract class RolesProviderService {
    public abstract canViewCartAndOrder(): Observable<boolean>;
    public abstract canViewOpenPositions(): Observable<boolean>;
    public abstract canSelectDeliveryOnly(): Observable<boolean>;
    public abstract canSelectCollectionOnly(): Observable<boolean>;
    public abstract canViewItems(): Observable<boolean>;
    public abstract canViewOrders(): Observable<boolean>;
    public abstract canViewCoa(): Observable<boolean>;
    public abstract isUsaCustomer(): Observable<boolean>;
    public abstract hasRole(role: string): Observable<boolean>;
    public abstract getRolesSnapshot(): string[];
    public abstract getRoles(): Observable<string[]>;
}
